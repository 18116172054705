import client from '@utils/client';

export const onCreateMandates = (payload) => {
  return client
    .post('/mandates/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onDeleteMandates = (id) => {
  return client
    .delete(`/mandates/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onUpdateMandates = (id, payload) => {
  return client
    .patch(`/mandates/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
