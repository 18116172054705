import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { RadioButton, CompanyNameAutocomplete } from '@components/form';
import { useTranslation } from '@contexts/translation';
import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import { onCreateLandlord, onUpdateLandlord } from "@api/landlords";
import { useFetchData } from '@api/fetchData';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}));

export default function LandlordsForm({
  id,
  isEdit,
  handleClose,
  operationId,
  fetchUrl,
  buttonText,
  setSelectValue = null,
}) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isCompany, setIsCompany] = useState(true);

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    getValues,
    control,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    data,
    isLoading,
    isError,
  } = isEdit
      ? useFetchData('/landlords/', id)
      : {
        data: null,
        isLoading: null,
        isError: null,
      };

  useEffect(() => {
    if (isEdit && data) {
      setIsCompany(data?.is_company);
      setValue('is_company', data?.is_company);
      setValue('company_name', data?.company_name);
      setValue('first_name', data?.first_name);
      setValue('last_name', data?.last_name);
      setValue('email', data?.email);
      setValue('phone', data?.phone);
      setValue('address', data?.address);
      setValue('note', data?.note);
    }
  }, [data, isEdit]);

  const onSubmit = async (values) => {
    setLoading(true);
    values.operations = operationId ? [Number(operationId)] : [];
    values.is_company = isCompany;

    let response;
    if (isEdit) {
      response = await onUpdateLandlord(values, id);
    } else {
      response = await onCreateLandlord(values);
    }
    if (response) {
      if (response.status === 200) {
        handleClose && handleClose();
        enqueueSnackbar(dictionary.landlords.landlord_edited, {
          variant: 'success',
        });
        mutate(fetchUrl);
      }
      if (response.status === 201) {
        handleClose && handleClose();
        enqueueSnackbar(dictionary.landlords.landlord_created, {
          variant: 'success',
        });
        mutate(fetchUrl);
        if (setSelectValue) setSelectValue(response?.data?.id);
      }
      if (response.status === 400) {
        setLoading(false);
        const { errors } = response.data;
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            });
          });
        }
      }
    }
    setLoading(false);
  };

  const radioChangeHandle = (e) => {
    if (e.target.value === 'true') {
      setIsCompany(true);
    } else if (e.target.value === 'false') {
      setIsCompany(false);
    }
  };

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <RadioButton
            fullWidth
            name="is_company"
            control={control}
            error={errors.is_company}
            errorMessage={errors.is_company && errors.is_company.message}
            onClick={radioChangeHandle}
          >
            <FormControlLabel
              value="true"
              label={dictionary["company"]}
              labelPlacement="start"
              control={<Radio checked={isCompany} />}
            />
            <FormControlLabel
              value="false"
              label={dictionary["person"]}
              labelPlacement="start"
              control={<Radio checked={!isCompany} />}
            />
          </RadioButton>
        </Grid>

        {isCompany && (
          <CompanyNameAutocomplete
            register={register}
            setValue={setValue}
            error={errors.company_name}
            helperText={errors.company_name && errors.company_name.message}
          />
        )}
        {!isCompany && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                type="text"
                name="first_name"
                id="first_name"
                label={dictionary["first_name"]}
                inputRef={register}
                error={errors.first_name}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.first_name && errors.first_name.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                type="text"
                id="last_name"
                name="last_name"
                label={dictionary["last_name"]}
                inputRef={register}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        )}

        {/* Email */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={dictionary.email}
            name="email"
            inputRef={register}
            error={errors.email}
            helperText={errors.email && errors.email.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={dictionary.phone}
            name="phone"
            inputRef={register}
            error={errors.phone}
            helperText={errors.phone && errors.phone.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Address */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={dictionary.address}
            name="address"
            inputRef={register}
            error={errors.address}
            helperText={errors.address && errors.address.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Note */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={dictionary.note}
            name="note"
            inputRef={register}
            error={errors.note}
            helperText={errors.note && errors.note.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {buttonText ? buttonText : dictionary.submit}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
