import React, { useState } from 'react';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import { useFetchData } from '@api/fetchData';
import { ActionTable, ModalDelete, ModalForm, Table, CellExpand, CellExpandCustom } from '@components/common';
import { useTranslation } from '@contexts/translation';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import Page404View from 'pages/404';
import Loader from '../common/Loader';
import { onDeleteMandates } from "@api/mandates";
import { Add as AddIcon } from "@material-ui/icons";
import MandatesForm from "./MandatesForm";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
  },
  numeric: {
    width: '100%',
    textAlign: 'right',
    paddingRight: 20,
  },
  tableNav: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const MandatesTable = ({ operationId, landlordId }) => {
  const classes = useStyles();
  const { lang, dictionary } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [idRow, setIdRow] = useState(null);

  const fetchUrl = landlordId && operationId ? `/mandates/?landlord=${landlordId}&operation=${operationId}` : landlordId ? `/mandates/?landlord=${landlordId}` : operationId ? `/mandates/?operation=${operationId}` : `/mandates/`;
  const { data, isLoading, isError } = useFetchData(fetchUrl);

  const rows = data && data.results;

  const actions = (idRow) => [
    {
      name: dictionary.edit,
      onClick: () => {
        setIsEdit(true);
        setIdRow(idRow);
      },
    },
    {
      name: dictionary.delete,
      onClick: () => {
        setIsDelete(true);
        setIdRow(idRow);
      },
    },
  ];


  const landlordColArray = !landlordId
    ? [
      {
        field: 'landlord',
        headerName: dictionary['navigation_landlord'],
        minWidth: 240,
        valueGetter: ({ row }) => row?.landlord?.display
      },
    ]
    : []

  const columns = [
    ...landlordColArray,
    {
      field: 'operation',
      headerName: dictionary['operation'],
      minWidth: 240,
      valueGetter: ({ row }) => row?.operation?.display,
      renderCell: CellExpand,
    },
    {
      field: 'start_date',
      headerName: dictionary['start_date'],
      minWidth: 180,
    },
    {
      field: 'duration',
      headerName: dictionary['duration'],
      type: 'number',
      minWidth: 120,
    },
    {
      field: 'renewal_date',
      headerName: dictionary.mandates.renewal_date,
      minWidth: lang === 'fr' ? 230 : 180,
    },
    {
      field: 'fee_rules',
      headerName: dictionary['rules'],
      minWidth: lang === 'fr' ? 210 : 180,
      flex: 1,
      renderCell: (params) => {
        let rules
        if (params.row.fee_rules && params.row.fee_rules.length > 0) {
          rules = params.row.fee_rules.map((rule) => `${rule.display}`).join(' / ');
        }
        return (
          <CellExpandCustom displayValue={rules} params={params} />
        )
      },
    },
    {
      field: 'surface',
      headerName: dictionary['surface'],
      type: 'number',
      minWidth: 140,
    },
    {
      field: 'action',
      headerName: dictionary.actions,
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return id && id !== 'Total' && <ActionTable actions={actions(id)} />;
      },
    },
  ]

  const operation_columns = [
    {
      field: 'start_date',
      headerName: dictionary['start_date'],
      minWidth: 180,
    },
    {
      field: 'duration',
      type: 'number',
      headerName: dictionary['duration'],
      minWidth: 120,
    },
    {
      field: 'renewal_date',
      headerName: dictionary.mandates.renewal_date,
      minWidth: lang === 'fr' ? 230 : 180,
    },
    {
      field: 'fee_rules',
      headerName: dictionary['rules'],
      minWidth: lang === 'fr' ? 210 : 180,
      flex: 1,
      renderCell: (params) => {
        let rules
        if (params.row.fee_rules && params.row.fee_rules.length > 0) {
          rules = params.row.fee_rules.map((rule) => `${rule.display}`).join(' / ');
        }
        return (
          <CellExpandCustom displayValue={rules} params={params} />
        )
      },
    },
    {
      field: 'surface',
      headerName: dictionary['surface'],
      type: 'number',
      minWidth: 140,
    },
    {
      field: 'action',
      headerName: dictionary.actions,
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return id && id !== 'Total' && <ActionTable actions={actions(id)} />;
      },
    },
  ]

  const handleFormOpen = () => setIsOpenForm(true);
  const handleFormClose = () => setIsOpenForm(false);
  const handleEditClose = () => setIsEdit(false);

  const handleDeleteToggle = () => setIsDelete(!isDelete);

  const onDelete = async () => {
    const response = await onDeleteMandates(idRow);
    if (response.status === 204) {
      mutate(fetchUrl);
      enqueueSnackbar(dictionary.deleted, {
        variant: 'success',
      });
      handleDeleteToggle();
    }
  };

  if (isLoading) return <Loader />;
  if (isError) return <Page404View />;

  return (
    <div>
      <Grid item xs={12}>
        <div className={classes.tableNav}>
          <div></div>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleFormOpen}
            >
              {dictionary.mandates.add_mandate}
            </Button>
          </div>
        </div>
        <Table
          loading={isLoading}
          columns={operationId ? operation_columns : columns}
          rows={rows}
        />
      </Grid>

      {(isOpenForm || isEdit) && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary.mandates.mandate}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <MandatesForm
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            id={idRow}
            fetchUrl={fetchUrl}
            operationId={operationId}
            landlordId={landlordId}
          />
        </ModalForm>
      )}

      {isDelete && (
        <ModalDelete onClose={handleDeleteToggle} onDelete={onDelete} />
      )}
    </div>
  );
};
export default MandatesTable;
