import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker, RadioButton, OperationsSelect, LandlordsSelect, Switch } from '@components/form';
import { useTranslation } from '@contexts/translation';
import { FormControlLabel, Grid, Radio, MenuItem } from '@material-ui/core';
import GetFormattedDate from '@utils/formatDate';
import UnformatDate from '@utils/unformatDate';
import { useFetchData } from '@api/fetchData';
import { onCreateMandates, onUpdateMandates } from "@api/mandates";
import AddIcon from '@material-ui/icons/Add';
import LandlordsForm from '@components/landlords/LandlordsForm';
import { ModalForm } from '@components/common';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function MandatesForm({
  id,
  isEdit,
  handleClose,
  fetchUrl,
  operationId,
  landlordId,
}) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)


  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control,
    watch
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  });

  const {
    data,
    isLoading,
    isError,
  } = isEdit
      ? useFetchData(`/mandates/`, id)
      : {
        data: null,
        isLoading: null,
        isError: null,
      };

  const { data: dataFee } = useFetchData(`/fees/?page_size=999`)


  const setRulesValues = rules => rules.filter(rule => rule.id).map(rule => setValue(`fee-${rule.id}`, true))

  useEffect(() => {
    if (isEdit && data) {
      setValue('operation', data?.operation && data.operation.id);
      if (data?.start_date) {
        setValue('start_date', UnformatDate(data?.start_date));
      }
      if (data && data.fee_rules.length > 0) {
        setRulesValues(data.fee_rules)
      }
      setValue('duration', data?.duration);
      setValue('is_renewed', data?.is_renewed);
      if (data?.renewal_date) {
        setValue('renewal_date', UnformatDate(data?.renewal_date));
      }
      if (data.landlord) {
        setValue('landlord', data?.landlord.id);
      }
      setValue('tax_rate', data?.tax_rate);
      setValue('surface', data?.surface);
    }
  }, [data, isEdit]);

  const onSubmit = async (values) => {
    setLoading(true);
    const listFee = Object.keys(values)
      .filter(key => key.includes('fee-'))
    if (listFee.length > 0) {
      let feeRulesItems = []
      listFee.map((fee) => {
        if (values[fee] === true) {
          let matches = fee.match(/(\d+)/);
          return feeRulesItems.push(matches[1])
        }
      })
      values['fee_rules'] = feeRulesItems
    }
    if (operationId) values.operation = Number(operationId);

    if (values.start_date) {
      values.start_date = GetFormattedDate(values.start_date);
    }
    if (values.renewal_date) {
      values.renewal_date = GetFormattedDate(values.renewal_date);
    }
    if (values.is_renewed === undefined || values.is_renewed === 'true') {
      values.is_renewed = true;
    }
    if (values.is_renewed === 'false') {
      values.is_renewed = false;
    }
    if (landlordId) {
      values.landlord = landlordId;
    }

    let response;
    if (isEdit) {
      response = await onUpdateMandates(id, values);
    } else {
      response = await onCreateMandates(values);
    }
    if (response) {
      if (response.status === 200) {
        handleClose && handleClose();
        enqueueSnackbar(dictionary.mandates.mandate_edited, {
          variant: 'success',
        });
        mutate(fetchUrl);
      }
      if (response.status === 201) {
        handleClose && handleClose();
        enqueueSnackbar(dictionary.mandates.mandate_created, {
          variant: 'success',
        });
        mutate(fetchUrl);
      }
      if (response.status === 400) {
        setLoading(false);
        const { errors } = response.data;
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            });
          });
        }
      }
    }
    setLoading(false);
  };


  const watchOperation = watch('operation')

  return (
    <>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>

          {!operationId &&
            <Grid xs="12" className={classes.center}>
              <OperationsSelect
                fullWidth
                control={control}
                error={errors.operation}
                helperText={errors.operation && errors.operation.message}
              />
            </Grid>
          }

          {!landlordId && (operationId || watchOperation) &&
            <LandlordsSelect
              fullWidth
              operationId={operationId ? operationId : watchOperation}
              control={control}
              error={errors.landlord}
              helperText={errors.landlord && errors.landlord.message}
              defaultValue=''
            >
              <MenuItem value={null} onClick={() => setOpen(true)}>
                <AddIcon /> {dictionary["create_landlord"]}
              </MenuItem>
            </LandlordsSelect>
          }

          {/* Start date */}
          <Grid item xs={12}>
            <DatePicker
              name="start_date"
              label={dictionary.start_date}
              variant="outlined"
              margin="normal"
              fullWidth
              control={control}
              error={errors.start_date}
              errorMessage={errors.start_date && errors.start_date.message}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              name="duration"
              id="duration"
              label={dictionary["duration_(in_months)"]}
              inputRef={register}
              error={errors.duration}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.duration && errors.duration.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Switch
              margin="normal"
              id={'is_renewed'}
              label={dictionary.mandates.is_renewable}
              name={`is_renewed`}
              control={control}
              error={errors.is_renewed}
              helperText={errors.is_renewed && errors.is_renewed.message}
              labelPlacement="end"
              switchColor="primary"
            />
          </Grid>

          {/* Renewal date */}
          <Grid item xs={12}>
            <DatePicker
              name="renewal_date"
              label={dictionary.mandates.renewal_date}
              variant="outlined"
              margin="normal"
              fullWidth
              control={control}
              error={errors.renewal_date}
              errorMessage={errors.renewal_date && errors.renewal_date.message}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              name="tax_rate"
              id="tax_rate"
              label={dictionary["tax_rate"]}
              inputRef={register}
              error={errors.tax_rate}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.tax_rate && errors.tax_rate.message}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              name="surface"
              id="surface"
              label={dictionary["surface"]}
              inputRef={register}
              error={errors.surface}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.surface && errors.surface.message}
            />
          </Grid>

          {dataFee && dataFee.results && dataFee.results.map((fee) => (
            <Grid xs="12">
              <Switch
                margin="normal"
                id={`fee-${fee.id.toString()}`}
                label={fee.display}
                name={`fee-${fee.id.toString()}`}
                control={control}
                error={errors.is_recuperable}
                helperText={errors.is_recuperable && errors.is_recuperable.message}
                labelPlacement="end"
                switchColor="primary"
              />
            </Grid>
          ))}


          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              startIcon={<SaveIcon />}
              className={classes.submit}
            >
              {dictionary.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
      {open &&
        <ModalForm type={dictionary['landlord']} onClose={() => setOpen(false)}>
          <LandlordsForm handleClose={() => setOpen(false)} operationId={operationId ? operationId : watchOperation} setSelectValue={id => setValue('landlord', id)} fetchUrl={`/landlords/?operations=${operationId ? operationId : watchOperation}`} />
        </ModalForm>
      }
    </>
  );
}
